'use client';
import React from 'react';
import styles from '../BaseHeader/index.module.css';
import OxenLogo from '@/ui/OxenLogo';
import Link from 'next/link';
import { colors } from '@/styles';
import { MaintenanceNavigation } from '@/ui/Header/Navigation';

import { Row } from '@/ui/primatives';

type HeaderProps = React.HTMLProps<HTMLDivElement> & {
  children?: React.ReactNode;
};

const MaintenanceHeader: React.FC<HeaderProps> = ({ children, ...rest }) => {
  return (
    <header
      className={styles.header}
      {...rest}
      style={{
        background: colors.secondary.lightGray1,
        borderBottom: `1px solid ${colors.secondary.lightGray3}`,
      }}
    >
      <div className={styles.globalBar} style={{ padding: '25px' }}>
        <Row gap={50} alignItems="center" justifyContent="space-between">
          <Row gap={20} alignItems="center">
            <Link href="/">
              <OxenLogo height={28} />
            </Link>
          </Row>
        </Row>
        <Row gap={50} justifyContent="flex-end" alignItems="center" className={styles.contextMenu}>
          <MaintenanceNavigation />
        </Row>
      </div>
      {children && <div className={styles.localBar}>{children}</div>}
    </header>
  );
};

export default MaintenanceHeader;
