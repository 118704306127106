'use client';
import React, { useEffect } from 'react';
import * as Styled from './styled';
import { useModal } from 'react-hooks-use-modal';
import * as Icon from '@/ui/Icon';
import Confetti from '@/ui/Confetti';
import Button from '@/ui/Button';
import Cookies from 'js-cookie';

import { ROOT_LAYOUT_DOM_NODE_ID } from '@/config/appSettings';
import { Stack } from '@/ui/primatives';

export type TModalContent = {
  component?: string;
  title: string;
  body?: string[];
  image?: string;
  link?: string;
  dialog?: boolean;
  confetti?: boolean;
};

const ModelOverlay: React.FC<{ invisible?: boolean }> = ({ invisible }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: invisible ? 'transparent' : 'rgba(0, 0, 0, 0.5)',
        zIndex: -1,
      }}
    />
  );
};

const ModalContent: React.FC<{ content: TModalContent }> = ({ content }) => {
  const { title, body, image, link, dialog } = content;
  return (
    <Stack.Centered gap={25}>
      {image && (
        <Styled.ModalImage>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={image} alt="Woohoo!" />
        </Styled.ModalImage>
      )}
      <h4>{content.title}</h4>
      <div>{body?.map((contentBlock, idx) => <p key={idx}>{contentBlock}</p>)}</div>
    </Stack.Centered>
  );
};

export const MessageModal: React.FC<{ content: TModalContent; afterOpen?: () => void }> = ({ content, afterOpen }) => {
  const [Modal, open, close, isOpen] = useModal(ROOT_LAYOUT_DOM_NODE_ID, {
    preventScroll: !content.dialog,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
    components: {
      Overlay: () => <ModelOverlay invisible={content.dialog} />,
    },
  });

  useEffect(() => {
    open();
    afterOpen?.();
  }, [open, afterOpen]);

  return (
    <>
      <Modal>
        <Styled.Modal>
          <Styled.ModalClose>
            <Button.Text onClick={close}>
              <Icon.X size="24px" onClick={close} />
            </Button.Text>
          </Styled.ModalClose>
          <ModalContent content={content} />
          {content.confetti && <Confetti density={1000} />}
        </Styled.Modal>
      </Modal>
    </>
  );
};

const ModalManager: React.FC = () => {
  const modalContent: TModalContent = !!Cookies.get('modalFlash') && JSON.parse(Cookies.get('modalFlash')!);
  if (!modalContent) return null;

  const afterOpen = () => {
    Cookies.remove('modalFlash');
  };

  return <MessageModal content={modalContent} afterOpen={afterOpen} />;
};

export default ModalManager;
