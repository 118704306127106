'use client';
import styled, { css } from 'styled-components';
import { colors, breakPoints } from '@/styles';

type ColorsType = {
  [key: string]: string;
};

const borderColors: ColorsType = {
  error: colors.semantic.negative.primary,
  success: colors.semantic.positive.dark,
  default: colors.secondary.lightGray3,
  info: colors.semantic.information.dark,
  warning: colors.semantic.notice.dark,
};

const backgroundColors: ColorsType = {
  error: colors.semantic.negative.light,
  success: colors.semantic.positive.light,
  default: colors.secondary.lightGray1,
  info: colors.semantic.information.light,
  warning: colors.semantic.notice.light,
};

export const FlashMessage = styled.div<{ $type: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  /* box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1); */
  /* border: 1px solid transparent; */
  /* border-radius: 5px; */
  color: ${(props) => borderColors[props.$type] || borderColors.default};
  /* border-color: ${(props) => borderColors[props.$type] || borderColors.default}; */
  /* border-width: 1px 1px 0 1px; */
  background: ${(props) => backgroundColors[props.$type] || backgroundColors.default};
  font-weight: 500;
  text-align: center;
`;
