'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  align-items: center;
  gap: 20px;
  margin-top: 50px;

  /* background-color: ${colors.secondary.lightGray1}; */
  & > * {
    /* width: 100%; */
    text-align: center;
  }
  img {
    height: 400px;
    object-fit: contain;
  }
  @media (max-width: ${breakPoints.mobile}) {
    padding-bottom: 20px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

export const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  @media (max-width: ${breakPoints.mobile}) {
    h2 {
      font-size: 52px;
    }
    h3 {
      font-size: 36px;
    }
  }
`;
