'use client';
import styled, { css } from 'styled-components';
import { colors, breakPoints } from '@/styles';

const gradientBorder = css`
  --border-angle: 0turn;
  border-radius: 8px;
  z-index: 0;

  & > * {
    border-radius: 5px;
    border: none;
  }

  --clr-1: #f87a7a;
  --clr-2: #f9947d;
  --clr-3: #f57ab1;
  --clr-4: #e07de8;
  --clr-5: #dbb2ed;
  --clr-6: #efb4c9;

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: -3px;
    z-index: -1;

    background: conic-gradient(
      from var(--border-angle),
      var(--clr-1),
      var(--clr-2),
      var(--clr-3),
      var(--clr-4),
      var(--clr-5),
      var(--clr-6),
      var(--clr-1)
    );
    border-radius: inherit;
    background-position: center center;
    background-repeat: no-repeat;
    animation: bg-spin 2s linear infinite;
  }

  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
  &:hover {
    animation-play-state: paused;
  }
  @property --border-angle {
    syntax: '<angle>';
    inherits: true;
    initial-value: 0turn;
  }
`;

const borderBlur = css`
  &:after {
    filter: blur(2rem);
    opacity: 0.5;
  }
`;

export const Modal = styled.div<{}>`
  /* border-radius: 5px; */
  display: flex;
  flex-direction: column;
  background: ${colors.primary.white};
  padding: 50px;
  max-width: 75vw;
  min-width: 350px;
  gap: 25px;
  z-index: 1000;

  h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${gradientBorder};
  ${borderBlur};

  /* background-image: linear-gradient(-60deg, #f87a7a, #f9947d, #f57ab1, #e07de8, #dbb2ed, #efb4c9); */
  /* background-image: linear-gradient(-60deg, #f87a7a, #e07de8); */
  /* color: ${colors.primary.white}; */
`;

export const ModalClose = styled.div<{}>`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  &:hover {
    stroke-width: 2px;
  }
`;

export const ModalImage = styled.div<{}>`
  border-radius: 100%;
  overflow: hidden;
  width: 150px;
  height: 150px;

  /* border: 1px solid ${colors.primary.black}; */

  /* ${gradientBorder}; */

  img {
    width: 100%;
    object-fit: cover;
  }
`;
