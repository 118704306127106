'use client';
import React from 'react';
import * as Styled from './styled';
import Cookies from 'js-cookie';

import { TMessageContent } from '.';

const FlashMessage: React.FC<{ content: TMessageContent }> = ({ content }) => {
  const { body, type } = content;
  // console.log('FlashMessage', { body, type });
  Cookies.remove('flashMessage');
  return <Styled.FlashMessage $type={type}>{body}</Styled.FlashMessage>;
};

export default FlashMessage;
